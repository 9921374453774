const ReportIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_4752_1877'
        maskUnits='userSpaceOnUse'
        x='2'
        y='3'
        width='21'
        height='19'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2 3H22.0137V21.1855H2V3Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_4752_1877)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.013 4.5C11.537 4.5 11.113 4.746 10.875 5.159L3.67701 17.724C3.44301 18.134 3.44501 18.623 3.68201 19.032C3.91901 19.441 4.34301 19.686 4.81601 19.686H19.199C19.671 19.686 20.095 19.441 20.332 19.032C20.57 18.623 20.572 18.134 20.336 17.724L13.151 5.159C12.914 4.746 12.49 4.5 12.013 4.5ZM19.199 21.186H4.81601C3.80201 21.186 2.89301 20.662 2.38401 19.784C1.87501 18.907 1.87201 17.858 2.37501 16.979L9.57501 4.413C10.081 3.528 10.992 3 12.013 3H12.014C13.034 3 13.947 3.529 14.453 4.415L21.639 16.979C22.142 17.858 22.139 18.907 21.63 19.784C21.121 20.662 20.212 21.186 19.199 21.186Z'
          fill='currentColor'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.005 17.499C11.452 17.499 11 17.052 11 16.499C11 15.946 11.443 15.499 11.995 15.499H12.005C12.558 15.499 13.005 15.946 13.005 16.499C13.005 17.052 12.558 17.499 12.005 17.499Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0039 14.164C11.5899 14.164 11.2539 13.828 11.2539 13.414V10.314C11.2539 9.90003 11.5899 9.56403 12.0039 9.56403C12.4179 9.56403 12.7539 9.90003 12.7539 10.314V13.414C12.7539 13.828 12.4179 14.164 12.0039 14.164Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ReportIcon;
