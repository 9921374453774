import { FC } from 'react';

import tagStyles from './TagStats.module.scss';

interface Props {
  result: {
    key: string;
    operator: string;
    operatorValue: number;
    keyValue: string;
  };
}

const TagStatsRow: FC<Props> = ({ result }) => {
  const calculateResult = () => {
    switch (result.operator) {
      case '*':
        return parseFloat(result.keyValue) * result.operatorValue;
      case '/':
        return parseFloat(result.keyValue) / result.operatorValue;
      case '+':
        return parseFloat(result.keyValue) + result.operatorValue;
      case '-':
        return parseFloat(result.keyValue) - result.operatorValue;
      default:
        return 0;
    }
  };

  return (
    <li className={tagStyles.statsListItem}>
      {result.key} : <strong>{result.keyValue}</strong>
      <br />
      <span>
        ({result.keyValue} {result.operator} {result.operatorValue} ={' '}
        {calculateResult()})
      </span>
    </li>
  );
};

export default TagStatsRow;
