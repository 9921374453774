import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { ArticleHighlight } from '~/types';

import ArticleSeeMoreButton from '../ArticleSeeMoreButton/ArticleSeeMoreButton';
import styles from './ArticleListHighlights.module.scss';

interface Props {
  list: ArticleHighlight[];
}

const ArticleListHighlights: FC<Props> = ({ list }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <motion.ul
        className={styles.list}
        initial={{ height: 104 }}
        animate={{ height: showAll ? 'auto' : 104 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      >
        {list.map(({ title }, index) => (
          <li
            key={index}
            className={styles.listItem}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        ))}
      </motion.ul>
      {list.length > 2 && (
        <ArticleSeeMoreButton isExpanded={showAll} setIsExpanded={setShowAll} />
      )}
    </>
  );
};

export default ArticleListHighlights;
