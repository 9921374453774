import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';

import ReportModal from '../ReportModal/ReportModal';
import styles from './ReportBlock.module.scss';

interface Props {
  article_id: number;
}

const ReportBlock: FC<Props> = ({ article_id }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.reportText}>
        Generated image for illustrative purposes:{' '}
        <button onClick={() => setShowModal(true)} className={styles.button}>
          Report
        </button>
      </div>
      <AnimatePresence>
        {showModal && (
          <ReportModal
            article_id={article_id}
            onClose={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ReportBlock;
